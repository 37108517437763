var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.productItem.isGrid ? 'product-grid' : 'product-list flex',
    'product-item cursor img-width-wrap'
  ],on:{"click":function($event){return _vm.goProductDetailHandler(_vm.productItem)}}},[_c('div',{class:[_vm.$route.name == 'ProductList' ? 'col-4' : '', 'product-img-wrap']},[_c('img',{attrs:{"src":_vm.productCover,"alt":""}}),(_vm.featureArr)?_c('div',{staticClass:"product-label-wrap"},_vm._l((_vm.featureArr),function(_,index){return _c('span',{key:index,class:['featured', _]},[_vm._v(" "+_vm._s(_ == 'New' ? '新品' : _ == 'Recommended' ? '推荐' : _ == 'Popular' ? '热销' : '')+" ")])}),0):_vm._e()]),_c('div',{class:[_vm.$route.name == 'ProductList' ? 'col-8' : '']},[_c('div',{staticClass:"product-title zh-related-title"},[_vm._v(" "+_vm._s(_vm.productItem.name)+" ")]),_c('div',{staticClass:"product-title en-related-title"},[_vm._v(_vm._s(_vm.productItem.summary))]),_c('div',{staticClass:"product-price"},[_c('span',{staticClass:"money-wrap"},[_c('span',{staticClass:"money-flag1"},[_vm._v("¥ ")]),_c('span',{staticClass:"money-num1",domProps:{"innerHTML":_vm._s(_vm.productPrice)}})])]),(!_vm.productItem.isGrid)?_c('vue-star',{attrs:{"score":_vm.productItem.score}}):_vm._e(),(!_vm.productItem.isGrid)?_c('ul',{staticClass:"product-categories"},_vm._l((_vm.productItem.tags),function(_){return _c('li',{key:_.tagId,on:{"click":function($event){$event.stopPropagation();return _vm.notToggleTagHandler(_.tagId)}}},[_vm._v(" "+_vm._s(_.name)+" ")])}),0):_vm._e(),_c('div',{staticClass:"button"},[_vm._v("购买")]),_c('div',{class:[
        'product-icon-wrap',
        _vm.$store.state.wishlist.indexOf(_vm.productItem.defaultSkuId) > -1 ||
        _vm.$store.state.wishlist.indexOf(_vm.productItem.skuId) > -1
          ? 'isFavorite'
          : ''
      ],on:{"click":function($event){$event.stopPropagation();return _vm.toggleWishlistHandler(_vm.productItem.defaultSkuId || _vm.productItem.skuId)}}},[_c('span',{staticClass:"bi-heart"})]),(_vm.productItem.isGrid && _vm.$store.state.device == 'pc')?_c('vue-star',{attrs:{"score":_vm.productItem.score}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }